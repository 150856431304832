import request from '@/utils/request'

//获取互动问答列表
export function getQaReplyList(params) {
    return request({
        url: '/portal/qa-reply/get-list',
        method: 'GET',
        params
    })
}

//回复点赞/取消点赞
export function setQaReplyLike(data) {
    return request({
        url: '/portal/qa-reply/like',
        method: 'POST',
        data
    })
}

//删除回复
export function QaReplyDelete(data) {
    return request({
        url: '/portal/qa-reply/delete',
        method: 'POST',
        data
    })
}

//修改互动问答回复
export function getQaReplyEdit(data) {
    return request({
        url: '/portal/qa-reply/edit',
        method: 'POST',
        data
    })
}

//发布互动问答回复
export function addQaReplyAdd(data) {
    return request({
        url: '/portal/qa-reply/add',
        method: 'POST',
        data
    })
}
// 互动问答主体详情
export function qareply_deatil(params){
    return request({
        url: '/portal/qa/get-info',
        method: 'get',
        params
    })
}
