<template>
  <div class="custom-dialog">
    <el-dialog
      class="customdialog"
      :visible.sync="dialogVisible"
      :show-close="showclose"
      :width="width"
      :custom-class="customClass"
      :append-to-body="appendtobody"
      :close-on-click-modal="false"
      @close="customClose"
    >
      <div slot="title" class="dialog-title">
        <slot name="headtitle">{{ title }}</slot>
      </div>
      <div class="line" />
      <div class="dialog-body">
        <slot name="dialogbody" />
      </div>
      <div slot="footer" class="dialog-footer">
        <slot name="dialogfooter" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Customdialog",
  components: {},
  props: {
    showclose: {
      type: Boolean,
      default: false,
    },
    appendtobody: {
      type: Boolean,
      default: false,
    },
    type: String, // 对话框类型：1.基础表单[base-dialog-form] 2.表格[base-dialog-table] 3.全屏 [base-dialog-fullscreen]
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    customClass () {
      let className = ""
      switch (this.type) {
        case "min0":
          className = "base-dialog-min0"
          break
        case "min":
          className = "base-dialog-min"
          break
        case "form":
          className = "base-dialog-form"
          break
        case "max":
          className = "base-dialog-max"
          break
        case "max2":
          className = "base-dialog-max2"
          break
        case "table":
          className = "base-dialog-table"
          break
        case "fullscreen":
          className = "base-dialog-fullscreen"
          break
      }
      return className
    },
  },
  created () { },
  methods: {
    customClose () {
      this.dialogVisible = false
      this.$emit('isDialogClose')
    },
    dialogopenbt () {
      this.dialogVisible = true
      this.$emit('isDialogVisible', this.dialogVisible)
    },
    dialogclosebt () {
      this.dialogVisible = false
    },
    dialogcancelbt () {
      this.dialogVisible = false
    },
    dialogenterbt () {
      this.dialogVisible = false
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-dialog {
  .customdialog {
    .dialog-title {
      margin: 0px;
      padding: 0px;
      margin-left: 27px;
      font-size: 20px;
      font-weight: 400;
      color: #222222;
    }

    .line {
      margin-top: 5px;
      margin-bottom: 0px;
      border-bottom: 1px solid #cccccc;
    }

    .dialog-body {
      width: 100%;
      flex: 1;
    }

    .dialog-footer {
      width: 100%;
      height: 68px;
    }
  }
}

::v-deep .el-dialog {
  background: #ffffff;
  box-shadow: 0px 1px 20px 0px rgba(35, 24, 21, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

::v-deep .el-dialog .el-dialog__header {
  margin: 0px;
  padding: 0px;
  width: 100%;
  flex-basis: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-dialog .el-dialog__headerbtn {
  top: 8px;
  font-size: 26px;
}

::v-deep .el-dialog .el-dialog__body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

::v-deep .el-dialog .el-dialog__footer {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

// 设置高度
.base-dialog-min0 {
  .dialog-body {
    min-height: 300px !important;
  }
}

.base-dialog-min {
  .dialog-body {
    min-height: 380px !important;
  }
}

.base-dialog-form {
  .dialog-body {
    min-height: 600px;
  }
}

.base-dialog-table {
  .dialog-body {
    min-height: 500px;
  }
}

.base-dialog-max {
  .dialog-body {
    min-height: 600px;
  }
}

.base-dialog-max2 {
  .dialog-body {
    min-height: 700px;
  }
}
</style>
